import { getUserLocalStorage, setUserLocalStorage, storeBeareToken } from 'shared/services/api/authentication';
import { IAuthProvider, IContext, IUser } from 'shared/interfaces';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { auth } from 'shared/services/api/authentication';
import { history } from 'shared/services/api/utils/history';

export const AuthContext = createContext<IContext>({} as IContext);

export const AuthProvider = ({ children }: IAuthProvider) => {
  const [profile, setProfile] = useState<IUser | null>();
  const [loading, setLoading] = useState(false);

  //Check if the user is logged in offline
  async function getUserOffline() {
    setLoading(false);
    const userOffline: IUser = getUserLocalStorage();

    if (userOffline) {
      setProfile(userOffline);
      storeBeareToken(userOffline.token);
      history.replace('/Private/Home');
    }
  }

  useEffect(() => {
    getUserOffline();
  }, []);

  const handleLogin = useCallback(async (email: string, password: string) => {
    setLoading(true);
    const response = await auth(email, password);

    if (response.email) {
      setProfile(response);
      setUserLocalStorage(response);
      storeBeareToken(response.token);
      getUserOffline();
    }
    setLoading(false);
    return response;
  }, []);

  function logout() {
    setProfile(null);
    setUserLocalStorage(null);
  }
  const isAuthenticated = useMemo(() => !!profile, [profile]);

  return (
    <AuthContext.Provider value={{ profile, isAuthenticated, logout, loading, login: handleLogin }}>
      {children}
    </AuthContext.Provider>
  );
};
